import React, { useRef, useState } from "react";
import { AutoComplete } from "antd";
import { ClearOutlined } from '@ant-design/icons';

const CustomAutoComplete = ({id, value, onChange, onSelect, options, placeholder, onClean}) => {
    const autocomplete = useRef(null);

    const [isOpen, setIsOpen] = useState(false);
    const [prevValue, setPrevValue]  = useState(value);

    const handleSelectAndClose = (value) => {
        if (autocomplete.current) {
            autocomplete.current.blur();
        }
            onSelect(value);
            setPrevValue(value);     
    };

    const handleInputChange = (newValue) => {
        onChange(newValue);
        if (!newValue) {
            onClean();
        }
    };

    const handleFocus = () => {
        if (autocomplete.current) {
            autocomplete.current.focus();
        }
    };

    const handleCleanBtn = () => {
        setPrevValue(value);  
        onClean();
        handleFocus();
    };

    const handleBlur = () => {
        if (isOpen) {
                onChange(prevValue); 
                setIsOpen(false);
        }
}

    return (
        <div className="navigation-elem" onBlur={handleBlur}>
            <AutoComplete
                id={id}
                ref={autocomplete}
                options={options}
                value={value}
                onChange={handleInputChange}
                style={{ margin: '5px 0', width: '95%', color: 'var(--primary-color)' }}
                placeholder={placeholder}
                onSelect={handleSelectAndClose}
                onBlur={() => isOpen && setIsOpen(false)}
                open={isOpen}
                onFocus={() => setIsOpen(true)}
                filterOption={(inputValue, option) =>
                    option.value.toUpperCase().includes(inputValue.toUpperCase())
                }
            />
            <ClearOutlined style={{ fontSize: '24px', paddingLeft: '5px' }}
                onClick={handleCleanBtn}
            />
        </div>
    );
};

export default CustomAutoComplete;
