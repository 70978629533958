import React, { useState, useEffect, useRef } from "react";
import * as Auth from "../AuthService";
import Image from "react-bootstrap/Image";
import './NavigationBar.css'
import api from "../NewApi";
import { properties, BATCH_WITH_ALL_MATERIALS } from "../properties";
import CustomAutoComplete from "./CustomAutoComplete";

class NavigationBarRule extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        }
    }

    render() {
        return (
            <div className="Navigation-attr">
                <a style={{ color: "var(--primary-color)", marginLeft: 24 }}
                    onClick={event => { event.preventDefault(); this.props.onRuleSelect(this.props.rule._id) }}
                    href={`/extract-manager`}><i> {this.props.rule.name}</i></a>
                <Image onClick={() => this.props.deleteRule()}
                    src={"/images/close-black.png"} />
            </div>
        );

    }
}

class NavigationBarAttribute extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            rules: []
        }
    }

    handleClick(event) {
        event.preventDefault();
        if (!this.state.isOpen) {
            if (this.state.rules.length === 0) {
                api.get(`/rules?class=${this.props.class.className}&attr=${this.props.attr}`, Auth.createConfig())
                    .then(json => this.setState({ rules: json.data.result }))
                    .catch(error => console.log(error));
            }
        }
        this.setState({ isOpen: !this.state.isOpen })
    }

    render() {
        const open_icon = <div style={{ overflowX: 'hidden' }}>
            <Image onClick={(event) => this.handleClick(event)}
                src={this.state.isOpen ? "/images/minus-box.png" : "/images/plus-box.png"} />
            <a style={{ color: this.props.current.attrName === this.props.attr ? "var(--primary-color)" : `${properties.colors.black}` }}
                onClick={event => { event.preventDefault(); if (this.props.attr !== 'IGNORE LIST') { this.props.handleAttributes(this.props.attr) } }}
                href={`/extract-manager`}>{this.props.attr !== 'IGNORE LIST' ? <i>{this.props.attr}</i> : <i>[ {this.props.attr} RULES ]</i>}</a>
        </div>;

        let rules = this.state.rules ? this.state.rules.map((v, i) => <NavigationBarRule rule={v}
            key={i}
            deleteRule={() => this.props.deleteRule(v)}
            onRuleSelect={(rule) => this.props.onRuleSelect(rule)} />) : [];

        return (
            <div className="Navigation-attr">
                {open_icon}
                {(this.state.isOpen && this.props.attr !== 'IGNORE LIST') && <a style={{ color: `${properties.colors.black}`, marginLeft: 24 }}
                    className="Navigation-attr"
                    onClick={(event) => { event.preventDefault(); this.props.addRule() }} >
                    Add rule
                </a>}
                {this.state.isOpen ? rules : null}
            </div>
        );

    }

}

class NavigationBarClass extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            mapped: [],
            attributes: []
        }
    }

    handleClick(event) {
        event.preventDefault();
        if (!this.state.isOpen) {
            if (this.state.attributes.length === 0) {
                api.get(`/attributes?class=${this.props.class.className}`, Auth.createConfig())
                    .then(json => this.setState({ attributes: json.data.items }))
                    .catch(error => console.log(error));
            }
            if (this.state.mapped.length === 0) {
                this.get_mapping_rules();
            }
        }
        this.setState({ isOpen: !this.state.isOpen })
    }

    get_mapping_rules() {
        api.get(`/mapping/rules/get?class=${this.props.class.className}`, Auth.createConfig())
            .then(json => this.setState({ mapped: json.data }))
            .catch(error => console.log(error));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.shouldUpdate !== this.props.shouldUpdate) {
            if (this.state.attributes) {
                this.setState({ attributes: [], mapped: [], isOpen: false })
            }
        }
        if (prevProps.active !== this.props.active && this.props.active) {
            document.getElementById(`#${this.props.class.className.replace(/ /g, '_')}`).scrollIntoView(true)
        }
    }

    render() {
        const open_icon = <div id={`#${this.props.class.className.replace(/ /g, '_')}`}>
            <Image onClick={(event) => this.handleClick(event)}
                src={this.state.isOpen ? "/images/minus-box.png" : "/images/plus-box.png"} />
            <a style={{ color: this.props.active ? "var(--primary-color)" : `${properties.colors.black}` }}
                onClick={(event) => { event.preventDefault(); this.props.handleClick() }}
                id={`#${this.props.class.className.replace(/ /g, '_')}`}>
                {this.props.class.className} ({this.props.class.count})
            </a>
        </div>;

        let attributes = this.state.attributes.map((v, i) =>
            <NavigationBarAttribute current={this.props.current}
                handleAttributes={attr => this.props.handleAttributes({ attrName: attr, 'class': this.props.class.className })}
                attr={v.attributeName}
                onRuleSelect={(rule) => this.props.onRuleSelect(rule)}
                rules={v.rules}
                deleteRule={(rule) => this.props.deleteRule(v, rule)}
                key={i}
                addRule={() => this.props.addRule(v.attributeName)}
                batch={this.props.batch}
                class={this.props.class} />);

        let rules = Array.isArray(this.state.mapped) && this.state.mapped.map((v, i) =>
            <NavigationBarRule rule={v}
                key={i}
                deleteRule={() => this.props.deleteRule('', v)}
                onRuleSelect={(rule) => this.props.onRuleSelect(rule)} />);

        return (
            <div className="Navigation-class">
                {open_icon}
                {this.state.isOpen ? rules : null}
                {this.state.isOpen && <a style={{ color: "var(--primary-color)", marginLeft: 24 }}
                    className="Navigation-attr"
                    onClick={(event) => { event.preventDefault(); this.props.addRule('') }} >
                    Add rule
                </a>}
                {this.state.isOpen ? attributes : null}
            </div>
        );

    }

}

const NavigationBarRoot = ({
    item,
    batch,
    shouldUpdate,
    windowHeight,
    attr,
    handleAttributes,
    addRule,
    deleteRule,
    onRuleSelect,
    handleClassChange,
    handleBatchChange
}) => {

    const [optionsBatches, setOptionsBatches] = useState([]);
    const [currentBatch, setCurrentBatch] = useState();
    const [optionsClasses, setOptionsClasses] = useState([]);
    const [currentClass, setCurrentClass] = useState();
    const [classes, setClasses] = useState([]);
    const [height, setHeight] = useState();
    // const [maxHeight, setMaxHeight] = useState(0);

    const searchBar = useRef();

    const redirectIfNeeded = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const batchParam = urlParams.get("batch");

        if (batchParam === "material-data-mapper" || batchParam === "equipment-data-mapper") {
            urlParams.set("batch", "FULL_MATERIALS");
            const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
            window.history.replaceState(null, '', newUrl);
        }
    };

    const updateHeight = () => {
        const offsetHeight = searchBar.current.offsetHeight;
        setHeight(offsetHeight);
        // setMaxHeight(windowHeight - offsetHeight - 100);
    };

    const fetchClasses = async () => {
        try {
            const re = await api.get(`/classes?batch=${batch}`, Auth.createConfig(), true);
            const classesData = re.data;

            setOptionsClasses(classesData.map((v) => ({
                value: v.className,
                label: (
                    <a href={`#${v.className.replace(/ /g, "_")}`}>
                        <span>{v.className}</span>
                    </a>
                ),
            })));
            setClasses(classesData);
        } catch (error) {
            console.log("Error while calling fetchClasses:", error.message);
        }
    }

    const getBatches = async () => {
        try {
            const re = await api.get("/batches", Auth.createConfig(), true);
            const batchesData = re.data;

            setOptionsBatches(batchesData.items.map((v) => ({
                value: v,
                label: v,
            })));

            let batchParam = new URLSearchParams(window.location.search.replace("%", "&")).get("batch");
            if (!batchParam) {
                batchParam = BATCH_WITH_ALL_MATERIALS;
            }

            handleBatchChange(batchParam, batchesData.results, batchesData.size, true);
            setCurrentBatch(batchParam);
        } catch (error) {
            console.log("Error while calling getBatches:", error.message)
        }
    };

    const handleClassSelect = (className) => {
        setCurrentClass(className);
        if (className) {
            handleClassChange(className);
        }
    };

    const handleBatchSelect = (batch) => {
        if (batch) {
            handleBatchChange(batch);
            setCurrentBatch(batch);
            setCurrentClass("");
        }
    };

    useEffect(() => {
        redirectIfNeeded();
        updateHeight();
        getBatches();
    }, []);

    useEffect(() => {
        if (item !== undefined || batch !== undefined || shouldUpdate !== undefined) {
            fetchClasses();
        }
    }, [item, batch, shouldUpdate]);

    useEffect(() => {
        updateHeight();
    }, [windowHeight]);

    const open_icon = (
        <div style={{ marginTop: 10 }}>
            <Image src="/images/minus-box.png" />
            <span
                onClick={() => handleClassChange("")}
                style={{ fontWeight: "bold" }}
            >
                Class Library
            </span>
        </div>
    );

    const renderedClasses = classes.map((v, i) => (
        <NavigationBarClass
            key={i}
            current={attr}
            handleAttributes={handleAttributes}
            active={v.className === item}
            addRule={(attr) => addRule(v, attr)}
            shouldUpdate={shouldUpdate}
            deleteRule={(attr, rule) => deleteRule(v, attr, rule)}
            onRuleSelect={onRuleSelect}
            handleClick={() => {
                handleClassChange(v.className);
                setCurrentClass(v.className);
            }}
            batch={batch}
            class={v}
        />
    ));

    return (
        <div>
            <div
                className="Navigation-Bar"
                ref={searchBar}
                style={{ maxHeight: height }}
            >
                <CustomAutoComplete
                    id={"combo-box-batch"}
                    options={optionsBatches}
                    value={currentBatch}
                    onChange={setCurrentBatch}
                    placeholder={"Search for batches"}
                    onSelect={handleBatchSelect}
                    onClean={() => setCurrentBatch("")}
                />

                <CustomAutoComplete
                    id={"combo-box-demo"}
                    options={optionsClasses}
                    value={currentClass}
                    onChange={setCurrentClass}
                    placeholder={"Search for classes"}
                    onSelect={handleClassSelect}
                    onClean={() => setCurrentClass("")}
                    disabled={!currentBatch}
                />
            </div>
            <div
                className="library"
                style={{ marginTop: height ? height + 5 : 0 }}
            >
                {open_icon}
                {batch && renderedClasses}
            </div>
        </div>
    );
};

export default NavigationBarRoot;
